import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button } from 'reactstrap';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {
    textFilter, customFilter, selectFilter, Comparator,
    FILTER_TYPES
} from 'react-bootstrap-table2-filter';
import Swal from 'sweetalert2';
import LoadingBar from "react-top-loading-bar";
import { RingLoader, FadeLoader, DotLoader, PropagateLoader } from 'react-spinners';

import AdminHeader from './AdminHeader';

import Download from './Download';

import UploadExcel from './UploadExcel';

class ProcedureTypeCurd extends Component {
    constructor(props) {
        super(props);
        this.state = {

            ShowProcedure: false,
            ShowBar: false,

            ShowTable: false,
            UploadModal:false,

            // To store response from api
            SpecialityList: [],
            ProcedureList: [],

            // Set Procedure List Title on Table of procedure and sub speciality
            ProcedureTypeName: '',

            // this unique id is for add update and delete operations of procedure and sub speciality
            speciality_type_id: '',

            // To toggle Add modals
            toggleProcedureAddModal: false,

            // to pass data from add modals
            newDataProcedure: {
                procedure_type: '',
            },

            // For validations of add modal
            ProcedureAddErr: '',

            //  To toggle Edit modals
            editProcedureModal: false,

            // to pass data from edit modals
            editProcedureData: {
                id: '',
                procedure_type: '',
            },

            // For validations of edit modal
            ProcedureEditErr: false,

            columns: [
                {
                    dataField: 'id' ,
                    text: 'ID',
                    align: 'center',
                    headerStyle: (colum, colIndex) => {
                    return { width: '20%', textAlign: 'center' };
                    },
                },
                {
                    dataField: 'procedure_type',
                    text: 'Name',
                    align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '40%', textAlign: 'center' };
                    },
                    filter: textFilter(),

                    filterRenderer: (onFilter, column) => {
                        this.onItemFilter = onFilter;
                        return null;
                    },
                },
                {
                    dataField: "Actions",
                    text: "Actions",
                    formatter: this.linkFollow,
                    align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { width: '30%', textAlign: 'center' };
                    },
                },
            ],

        };
    }

    componentDidMount() {
        this.setState({
            ShowBar: true,
        })
        const headers = new Headers()

        const options = {
            method: 'POST',
        }

        fetch(global.websiteUrl+'/service/Speciality/get_SpecialityList', options)
            .then((Response) => Response.json())
            .then((findresponse) => {
                console.log(findresponse)
                var testDataSpecialityList = findresponse.data;
                console.log("testDataSpecialityList", JSON.stringify(testDataSpecialityList));
                this.setState({
                    SpecialityList: findresponse.data,
                })
                this.setState({
                    ShowBar: false,
                })
            })
    }

    ProcedureItemSelectedFunc = async (event) => {
        console.log("list item selected", event.target);
        console.log("list item selected id", event.target.value);
        console.log("list item selected name", event.target.type);

        await this.setState({
            ShowTable: true,
            ProcedureTypeName: event.target.type,
            speciality_type_id: event.target.value,
        });
        this.fetchProcedureData();
    }

    // to fetch table data for Procedure
    fetchProcedureData() {
        this.setState({
            ShowProcedure: true,
        })

        const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
        let formData = new FormData();
        formData.append('speciality_type_id', this.state.speciality_type_id);
        formData.append('search_type', "Procedure");

        const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
        }
        axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                var lgdata = response.data;
                console.log("lgdata", JSON.stringify(lgdata));
                var ProcedureStatus = response.data.status;
                console.log("ProcedureStatus", JSON.stringify(ProcedureStatus));
                if (ProcedureStatus == false || ProcedureStatus ==undefined) {
                    Swal.fire({
                        icon: 'error',
                        title: 'No data to show for this Category',
                        confirmButtonColor: '#d33',
                        confirmButtonText: 'OK'
                    })
                    this.setState({
                        ProcedureList: [],
                    })
                }
                else {
                    this.setState({
                        ProcedureList: response.data.data,
                    })
                    console.log("ProcedureList", this.state.ProcedureList)
                }
                this.setState({
                    ShowProcedure: false,
                })
            })
            .catch(error => {
                console.log(error);
            });
    }

    // Procedure type all functions
    // Funtion for displaying 'Edit' & 'delete' buttons inside Bootstrap Grid
    linkFollow = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                <button class="cetas-button cetas-button-small"
                    onClick={this.editProcedure.bind(this, row.id, row.procedure_type,)}>
                    Edit
                </button>
                &nbsp;&nbsp;
                <button class="cetas-secondry-button cetas-button-small"
                    onClick={this.deleteProcedure.bind(this, row.id)}>
                    Delete
                </button>
            </div>
        );
    };

    toggleProcedureAdd() {
        this.setState({
            toggleProcedureAddModal: !this.state.toggleProcedureAddModal,
            ProcedureAddErr: '',
        });
    }

    toggleeditProcedureModal() {
        this.setState({
            editProcedureModal: !this.state.editProcedureModal,
            ProcedureEditErr: '',
        });
    }


    toggle_download_page=()=>
        {
            this.setState({
                ShowBar: true,
                ShowProcedure:true,
            })
            Download("Procedure List");
    
        }

    toggleUploadModal=()=>
    {       
      sessionStorage.setItem("UploadType","Procedure"); 
       
       this.setState({
                UploadModal:!this.state.UploadModal,
       })

    }

    editProcedure(id, procedure_type) {
        this.setState({
            editProcedureData: { id, procedure_type },
            editProcedureModal: !this.state.editProcedureModal
        });
    }

    validateIntOncAdd = () => {
        let ProcedureAddErr = '';
        if (!this.state.newDataProcedure.procedure_type) {
            ProcedureAddErr = 'Please Enter Item name';
        }
        if (ProcedureAddErr) {
            this.setState({ ProcedureAddErr });
            return false;
        }
        return true;
    }

    procedureAdd() {
        const isValid = this.validateIntOncAdd();
        console.log("in add modal")
        console.log("speciality_id", this.state.speciality_type_id);
        console.log("procedure-type", this.state.newDataProcedure.procedure_type);

        if (isValid) {
            this.setState({
                ShowProcedure: true,
            })
            const url = global.websiteUrl+'/service/Procedure_Speciality/procedure_speciality_curd';

            let formData = new FormData();
            formData.append('oper', "add");
            formData.append('procedure_type', this.state.newDataProcedure.procedure_type);
            formData.append('speciality_id', this.state.speciality_type_id);

            const config = {
                // headers: { 'content-type': 'multipart/form-data' }
            }

            axios.post(url, formData, config)
                .then(response => {
                    console.log(response.data);
                    this._refreshDropdownTable();
                    this.setState({
                        ShowProcedure: false,
                    })
                    if (response.data.status == true) {
                        Swal.fire({
                            icon: 'success',
                            text: response.data.message,
                            confirmButtonColor: '#d33',
                            confirmButtonText: 'OK'
                        })
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            text: response.data.message,
                            confirmButtonColor: '#d33',
                            confirmButtonText: 'OK'
                        })
                    }
                })
                .catch(error => {
                    console.log(error);
                });

            this.setState({
                toggleProcedureAddModal: false, newDataProcedure: {
                    procedure_type: '',
                }
            });
        }
    }

    _refreshDropdownTable() {
        console.log("in refresh");
        const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
        let formData = new FormData();
        // formData.append('speciality_type_name',this.state.speciality_type_name);
        // formData.append('search_type', this.state.search_type);

        formData.append('speciality_type_id', this.state.speciality_type_id);
        formData.append('search_type', "Procedure");

        const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
        }
        axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                var lgdata = response.data.data;
                console.log("lgdata", JSON.stringify(lgdata));
                this.setState({
                    ProcedureList: lgdata,
                })
                console.log("ProcedureList", this.state.ProcedureList)
            })
            .catch(error => {
                console.log(error);
            });
    }

    validateIntOncEdit = () => {
        let ProcedureEditErr = '';
        if (!this.state.editProcedureData.procedure_type) {
            ProcedureEditErr = 'Please Enter Item name';
        }
        if (ProcedureEditErr) {
            this.setState({ ProcedureEditErr });
            return false;
        }
        return true;
    }

    updateProcedure() {
        const isValid = this.validateIntOncEdit();
        if (isValid) {
            this.setState({
                ShowProcedure: true,
            })
            console.log("in edit modal")
            console.log("speciality_unique_id", this.state.speciality_type_id);
            console.log("procedure-type id", this.state.editProcedureData.id);
            console.log("procedure-type data to edit", this.state.editProcedureData.procedure_type);
            if (isValid) {
                const url = global.websiteUrl+'/service/Procedure_Speciality/procedure_speciality_curd';

                let formData = new FormData();
                formData.append('oper', "edit");
                formData.append('procedure_type', this.state.editProcedureData.procedure_type);
                formData.append('id', this.state.editProcedureData.id);
                formData.append('speciality_id', this.state.speciality_type_id);

                const config = {
                    // headers: { 'content-type': 'multipart/form-data' }
                }

                axios.post(url, formData, config)
                    .then(response => {
                        console.log(response.data);
                        this._refreshDropdownTable();
                        this.setState({
                            ShowProcedure: false,
                        })
                        if (response.data.status == true) {
                            Swal.fire({
                                icon: 'success',
                                text: response.data.message,
                                confirmButtonColor: '#d33',
                                confirmButtonText: 'OK'
                            })
                        }
                        else {
                            Swal.fire({
                                icon: 'error',
                                text: response.data.message,
                                confirmButtonColor: '#d33',
                                confirmButtonText: 'OK'
                            })
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });

                this.setState({
                    editProcedureModal: false, editProcedureData: {
                        id: '',
                        procedure_type: '',
                    }
                });
            }
        }
    }

    // deleteProcedure(id) {
    //     console.log(id);
    //     let idtoDelete = JSON.parse(id);
    //     const url = global.websiteUrl+'/service/Procedure_Speciality/procedure_speciality_curd';

    //     let formData = new FormData();
    //     formData.append('oper', "del");
    //     formData.append('id',idtoDelete);
    //     formData.append('speciality_id', this.state.speciality_type_id);

    //      const config = {     
    //             // headers: { 'content-type': 'multipart/form-data' }
    //         }

    //         axios.post(url, formData, config)
    //             .then(response => {
    //                 console.log(response.data);
    //                 this._refreshDropdownTable();
    //                 if(response.data.status == true)
    //                 {
    //                   Swal.fire({
    //                     icon: 'success',
    //                     text: response.data.message,
    //                     confirmButtonColor: '#d33',
    //                     confirmButtonText: 'OK'
    //                   })
    //                 }
    //                 else{
    //                   Swal.fire({
    //                     icon: 'error',
    //                     text: response.data.message,
    //                     confirmButtonColor: '#d33',
    //                     confirmButtonText: 'OK'
    //                   })
    //                 }
    //             })
    //             .catch(error => {
    //                 console.log(error);
    //             });
    // }

    deleteProcedure(id) {
        console.log(id);
        let idtoDelete = JSON.parse(id);

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success ',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then((result) => {

            if (result.value) {
                this.setState({
                    ShowProcedure: true,
                })
                console.log(id);
                let idtoDelete = JSON.parse(id);
                const url = global.websiteUrl+'/service/Procedure_Speciality/procedure_speciality_curd';

                let formData = new FormData();
                formData.append('oper', "del");
                formData.append('id', idtoDelete);
                formData.append('speciality_id', this.state.speciality_type_id);

                const config = {
                    // headers: { 'content-type': 'multipart/form-data' }
                }

                axios.post(url, formData, config)
                    .then(response => {
                        console.log(response.data);
                        this._refreshDropdownTable();
                        if (response.data.status == true) {
                            Swal.fire({
                                icon: 'success',
                                text: response.data.message,
                                confirmButtonColor: '#d33',
                                confirmButtonText: 'OK'
                            })
                        }
                        else {
                            Swal.fire({
                                icon: 'error',
                                text: response.data.message,
                                confirmButtonColor: '#d33',
                                confirmButtonText: 'OK'
                            })
                        }
                        this.setState({
                            ShowProcedure: false,
                        })
                    })
                    .catch(error => {
                        console.log(error);
                    });

            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                // swalWithBootstrapButtons.fire(
                //   'Cancelled',
                //   'Your imaginary file is safe :)',
                //   'error'
                // )
            }
        })
    }

    onItemChange = (event) => {
        const { value } = event.target;
        this.onItemFilter(value);
    };

    render() {
        return (
            <body>
                <div style={{marginTop:'15vh'}} >

                    {this.state.ShowBar ? <div style={{ position: 'fixed', top: '0' }}>
                        <LoadingBar
                            progress='100'
                            // onLoaderFinished={() => setProgress(0)}
                            //  progress={progress}
                            height={3} color="#A90007" transitionTime={4000}
                            loaderSpeed={4000} />
                    </div>
                        : null}

                    {this.state.ShowProcedure ? <Modal isOpen={true} className='propagateLoader' centered>
                        <PropagateLoader color={'#A90007'} size='40' loading='true' />
                    </Modal> : null}
                    <div >
                        <div >
                            <AdminHeader />
                            <div class="container">
                                <div class="content-wrapper">
                                    <div class="screen-headingbox">
                                        <div className="back-section">
                                            <a>
                                                <Link to="/AdminDashboard">
                                                    <img
                                                        src={require('../Assets/Images/back-arrow.svg')}
                                                    />
                                                    Back
                                                </Link>
                                            </a>
                                        </div>
                                        <button className="cetas-button float-right"
                                                            data-toggle="modal" data-target="#new-empl-add"
                                                            onClick={this.toggleUploadModal.bind()}
                                                           >Upload Procedure List</button>
                                        <button className="cetas-button float-right"
                                                            data-toggle="modal" data-target="#new-empl-add"
                                                            onClick={this.toggle_download_page.bind()}
                                                           >Download Procedure List</button>
                                        <h2>Procedure Type Management</h2>

                                    </div>
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 col-xs-12 profile-box">
                                            <div className="sidebar" >
                                                <ul className="cetas-leftnav">
                                                    <li class="submenu-link">
                                                        <a onClick={this.procedureType}>
                                                            <text style={{ color: '#A90007', fontWeight: 'bold' }}>Speciality Types</text>
                                                            {/* Procedure Types */}
                                                            <img style={{ marginLeft: '10%', width:'20px' , height:'20px' }}
                                                                src={require('../Assets/Images/back-arrow.svg')}
                                                            />
                                                        </a>
                                                        {
                                                            <ul>
                                                                {this.state.SpecialityList.map(item => (
                                                                    <li value={item.id} type={item.speciality_type}
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={this.ProcedureItemSelectedFunc} key={item.id}
                                                                    //  className={
                                                                    //     this.state.speciality_type_id == item.id ? "selected" : ""
                                                                    // }
                                                                    //  className={`segmentsList${this.state.speciality_type_id === item.id ? 'selected' : ''}`}
                                                                    >
                                                                        {item.speciality_type}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        }
                                                    </li>
                                                </ul>
                                                <div style={{ marginTop: '10px' }}></div>
                                            </div>
                                        </div>
                                        <div class="col-md-8 col-sm-12 col-xs-12 profile-box">
                                            <div class="form-rightsec">
                                                {
                                                    (this.state.ShowTable) ? (
                                                        <div>
                                                            {/* <div className="screen-headingbox">
                                                                <h3>{this.state.ProcedureTypeName}<button className="cetas-button float-right"
                                                                    data-toggle="modal" data-target="#new-empl-add"
                                                                    onClick={this.toggleProcedureAdd.bind(this)}>+ Add Item</button></h3>
                                                            </div> */}
                                                            {
                                                                (this.state.ProcedureList.length > 0) ? (
                                                                    <div className="emplist-box" style={{ marginTop: '5%', marginBottom: '5%' }}>
                                                                        <div className="employee-actions">
                                                                            <div className="table-infobox">
                                                                                <p className="list-count" style={{ color: 'white' }}>
                                                                                    {this.state.ProcedureList.length} Items listed
                                                                                </p>
                                                                                <div className="filter-box float-right mb-30" style={{ marginTop: '1.5%' }}>
                                                                                    <div style={{ width: '70%', }} className="input-group float-right">
                                                                                        <div className="input-group-prepend">
                                                                                            <div className="input-group-text">
                                                                                                <img src={require("../Assets/Images/search.svg")} />
                                                                                            </div>
                                                                                        </div>
                                                                                        <input type="text" className="form-control"
                                                                                            id="inlineFormInputGroupUsername"
                                                                                            placeholder="Search by Name"
                                                                                            onChange={(e) => { this.onItemChange(e) }} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ marginTop: '7%' }}>
                                                                            <BootstrapTable keyField='id'
                                                                                data={this.state.ProcedureList}
                                                                                columns={this.state.columns}
                                                                                filter={filterFactory()}
                                                                                striped
                                                                                hover
                                                                                condensed
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className="emplist-box" style={{ marginTop: '5%', marginBottom: '5%' }}>
                                                                        <div className="employee-actions">
                                                                            <div className="table-infobox">
                                                                                <p className="list-count" style={{ color: 'white' }}>
                                                                                    {this.state.ProcedureList.length} Items listed
                                                                                </p>
                                                                                <div className="filter-box float-right mb-30">
                                                                                    <div style={{ width: '70%', }} className="input-group float-right">
                                                                                        <div className="input-group-prepend">
                                                                                            <div className="input-group-text">
                                                                                                <img src={require("../Assets/Images/search.svg")} />
                                                                                            </div>
                                                                                        </div>
                                                                                        <input type="text" className="form-control"
                                                                                            id="inlineFormInputGroupUsername"
                                                                                            placeholder="Search by Name"
                                                                                            onChange={(e) => { this.onItemChange(e) }} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <BootstrapTable keyField='id'
                                                                            data={[]}
                                                                            noDataIndication="Table is Empty"
                                                                            columns={this.state.columns}
                                                                            filter={filterFactory()}
                                                                            striped
                                                                            hover
                                                                            condensed
                                                                        />
                                                                    </div>
                                                                )
                                                            }
                                                            <div style={{ marginBottom: '30%' }}></div>
                                                        </div>
                                                    ) : (
                                                        <div style={{ marginTop: '25%', marginLeft: '20%' }}>
                                                            <h4>Please Select Speciality</h4>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Add Procedure Type Modal*/}
                                <Modal isOpen={this.state.toggleProcedureAddModal} toggle={this.toggleProcedureAdd.bind(this)}>
                                    <ModalHeader toggle={this.toggleProcedureAdd.bind(this)}
                                        cssModule={{ 'modal-title': 'w-100 text-center', 'border-bottom': '0px', }}
                                        style={{ borderBottom: '0px' }}>
                                        <h3 style={{ justifyContent: 'center', marginTop: '10px' }} id="login-tit">Add New Item</h3>
                                    </ModalHeader>
                                    <ModalBody>
                                        {/* <FormGroup>
                    <Input id="id" value={this.state.newDataProcedure.id} readOnly hidden />
                  </FormGroup> */}
                                        <FormGroup>
                                            <Label for="AddIntOnco">Add Item in {this.state.ProcedureTypeName} </Label>
                                            <Input id="title" value={this.state.newDataProcedure.procedure_type} onChange={(e) => {
                                                let { newDataProcedure } = this.state;
                                                newDataProcedure.procedure_type = e.target.value;
                                                this.setState({ newDataProcedure });
                                            }} />
                                            <div style={{ fontSize: 12, color: "red" }}>{this.state.ProcedureAddErr}</div>
                                        </FormGroup>
                                    </ModalBody>
                                    <ModalFooter>
                                        <button class="cetas-button cetas-button-small" style={{ height: '40px' }}
                                            onClick={this.procedureAdd.bind(this)}>Add Item</button>
                                        <button class="cetas-secondry-button cetas-button-small" style={{ height: '40px', width: '80px' }}
                                            onClick={this.toggleProcedureAdd.bind(this)}>Cancel</button>
                                    </ModalFooter>
                                </Modal>

                                {/*  Edit procedure Modal*/}
                                <Modal isOpen={this.state.editProcedureModal} toggle={this.toggleeditProcedureModal.bind(this)} >
                                    <ModalHeader toggle={this.toggleeditProcedureModal.bind(this)}
                                        cssModule={{ 'modal-title': 'w-100 text-center', 'border-bottom': '0px', }}
                                        style={{ borderBottom: '0px' }}>
                                        <h3 style={{ justifyContent: 'center', marginTop: '10px' }} id="login-tit">Edit Item</h3>
                                    </ModalHeader>
                                    <ModalBody>
                                        <FormGroup>
                                            <Label for="category">Update {this.state.ProcedureTypeName}</Label>
                                            <Input id="title" value={this.state.editProcedureData.procedure_type} onChange={(e) => {
                                                let { editProcedureData } = this.state;
                                                editProcedureData.procedure_type = e.target.value;
                                                this.setState({ editProcedureData });
                                            }} />
                                            <div style={{ fontSize: 12, color: "red" }}>{this.state.ProcedureEditErr}</div>
                                        </FormGroup>
                                    </ModalBody>
                                    <ModalFooter>
                                        <button class="cetas-button cetas-button-small" style={{ height: '40px' }}
                                            onClick={this.updateProcedure.bind(this)}>Update</button>
                                        <button class="cetas-secondry-button cetas-button-small" style={{ height: '40px', width: '80px' }}
                                            onClick={this.toggleeditProcedureModal.bind(this)}>Cancel</button>
                                    </ModalFooter>
                                </Modal>
                            </div>
                        </div>
                    </div>





        <Modal isOpen={this.state.UploadModal}
            toggle={this.toggleUploadModal.bind(this)}
            centered>
                               
                               <ModalHeader
              toggle={this.toggleUploadModal.bind(this)}
              cssModule={{
                "modal-title": "w-100 text-center",
                "border-bottom": "0px",
              }}
              style={{ borderBottom: "0px" }}
            >
              <h3
                style={{ justifyContent: "center", marginTop: "10px" }}
                id="login-tit"
              >
                
              </h3>
            </ModalHeader>

            <ModalBody style={{ padding: "0px 70px 0px 70px" }}>
              <UploadExcel></UploadExcel>
            </ModalBody>
                   
                   
                                </Modal>



                    <footer class="footer-fixed">
                        <div class="cetas-footer">
                            <div class="container">
                                <p>All rights reserved. www.cetashealthcare.com</p>
                            </div>
                        </div>
                    </footer>
                </div>
            </body>
        );
    }
}

export default ProcedureTypeCurd;