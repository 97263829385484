import React, { Component } from "react";
import Swal from 'sweetalert2';
import axios from 'axios';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

// class Download extends Component
// {

//   msgfun()
//     {
//         alert('hi');
    
//     }
    
// }


function Download(fileName)
 {
    
    
    ExportInExcel(fileName);
   
}


function ExportInExcel(fileName)
    {   
        
        
        
        let csvData=[];
        let url;
        // Export Personal Information and Biography
        let formData = new FormData();
       
        if(fileName=="Speciality List")
        {
           url = global.websiteUrl+'/service/Speciality/get_SpecialityList';
           formData.append('search_type', 'Speciality');
      
        
        }
        else if (fileName=="Sub Speciality List")
            {
                url = global.websiteUrl+'/service/Procedure_Speciality/get_Complete_Sub_SpecialityList';
                formData.append('search_type', 'Sub Speciality');
      
            
            }
            else if (fileName=="Procedure List")
                {
                
                    url = global.websiteUrl+'/service/Procedure_Speciality/get_Complete_Sub_SpecialityList';
                    formData.append('search_type', 'Procedure');
          
                
                }else if (fileName=="Condition List")
                    {
                    
                        url = global.websiteUrl+'/service/Procedure_Speciality/get_Complete_Sub_SpecialityList';
                        formData.append('search_type', 'Condition');
              
                    
                    }
      
        const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
                }

        axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                csvData = response.data.data;
                 if(response.data.status==true)
                {
                    if(response.data.data.length>0)
                    {
                         exportexcel(csvData,fileName)                
                       
                            Swal.fire({
                                icon: 'success',
                                text: 'Download Success',             
                                confirmButtonColor: '#d33',
                                confirmButtonText: 'OK'
                            }).then((result)=>
                            {
                                if(result.value)
                                {
                                    window.location.reload(true);
                                }
                            }
                        )
                    }
                    else
                    {
                    

                            Swal.fire({
                                icon: 'success',
                                text: 'No Record Found in selected date range',             
                                confirmButtonColor: '#d33',
                                confirmButtonText: 'OK'
                            }).then((result)=>
                                {
                                    if(result.value)
                                    {
                                        window.location.reload(true);
                                    }
                                }
                            )
                    }

                    }
                    else
                    {
                      
                        Swal.fire({
                            icon: 'error',
                            text:'Download failed',
                            confirmButtonColor: '#d33',
                            confirmButtonText: 'OK'
                          }).then((result)=>
                            {
                                if(result.value)
                                {
                                    window.location.reload(true);
                                }
                            }
                        )
                    }


            });

       
    }

function exportexcel(csvData,fileName){
        //const csvData= this.state.projectList;
        //const fileName="test";

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';    
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'Sheet1': ws }, SheetNames: ['Sheet1'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
       
}




export default Download;